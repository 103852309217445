export function removeMember (members) {
    return doPost('/team/remove', {members});
}

export function getMemberById (userId) {
    return doPost('/team/search-members', {userId})
}


export function inviteMembers (members, message) {
    return doPost('/team/invite-members', { members, message })
}

function doPost (path, payload) {
    return axios.post(path, payload, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

