$( document ).ready(function() {

    $( ".login-selector" ).click(function( event ) {

        if(!$(this).hasClass('active')){
            $(".login-selector").toggleClass('active');
            $(".login-form").toggleClass('hide');
        }
    });

    $(".login-button").click(function(e){
        $(this).toggleClass('loading');
    });

});
