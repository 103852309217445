<template>

    <div id="dashboard" class="">
        <div class="container main-content">
            <MainContentComponent :page="page" :username="username"
                                  :user_group_id="user_group_id" :ggpdata="this.ggpdata"></MainContentComponent>
            <requests-component :ggpdata="this.ggpdata"></requests-component>

        </div>
    </div>
</template>

<script>

    import MainContentComponent from './dashboard/MainContentComponent.vue'

    export default {
        props: ['username', 'page', 'user_group_id', 'ggpdata'],
        mounted () {
            console.log('Component mounted.')
            console.log(this.user_group_id)
        },
        components: {
            MainContentComponent
        },
        methods: {
            pageChange: () => {
                console.log('page change dashboard-component')
                // this.props.page = page;
            }
        }
    }
</script>
