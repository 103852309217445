$(document).ready(function () {

    var postUrl = $('#generateUrlForm').attr('action')
    var token = $('input[name ="_token"]').attr('value')
    $('#generateSignupDropdown').click(() => {
        $.ajax({
            url: postUrl,
            type: 'post',
            headers: {
                'X-CSRF-TOKEN': token,   //If your header name has spaces or any other char not appropriate

            },
            accept: 'application/json',
            success: function (data) {
                console.log(data);
            }
        })
    })

    $('#logout').click(() => {
        $.ajax({
            url: "/logout",
            type: 'post',
            headers: {
                'X-CSRF-TOKEN': token,   //If your header name has spaces or any other char not appropriate

            },
            accept: 'application/json',
            success: function (data) {
                console.log(data);
            }
        })
    })

})
