<template>
  <div id="order-form" class="container">
    <div class="success-view" v-if="success">
      <i class="fa fa-check-circle" aria-hidden="true"></i>
      <p class="header">Request has been submitted</p>
      <b-link href="/requests" class="btn ggp-btn-blue">See Request</b-link>
      <b-link href="/template" class="btn ggp-btn-blue"
        >Browse Template
      </b-link>
    </div>
    <div v-else>
      <div class="ggp-bread-crumb">
        All Template >
        {{ this.editMode == true ? "Edit Request" : "Initiate Request" }}
      </div>
      <h3 class="page_header">
        {{ this.editMode == true ? "Edit Request" : "Initiate Request" }}
      </h3>
      <div class="form-section">
        <b-form inline @submit="onSubmit" v-if="show">
          <div class="w-100 row form-input-area">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="form-field">
                <div
                  class="w-100 mb-4"
                  v-for="(blocks, index) in form"
                  :key="index"
                  v-if="index !== 'dependent' && index !== 'hidden'"
                >
                  <div class="form-section-header">
                    {{ index }}
                  </div>
                  <div
                    class="form-row row"
                    v-for="(block, inner_index) in blocks"
                    :key="inner_index"
                  >
                    <label
                      class="justify-content-start form-label col-md-5"
                      :for="index"
                      >{{ block.label }}</label
                    >
                      <b-form-textarea
                          v-if="block.type == 'textflow'"
                          :id="block.name"
                          v-model="submitData[block.name]"
                          :placeholder="block.default"
                          class="form-input col-md-7">
                      </b-form-textarea>
                      <b-form-input
                          v-else
                          :id="block.name"
                          v-model="submitData[block.name]"
                          :placeholder="block.default"
                          class="form-input col-md-7"
                      ></b-form-input
                      >
                  </div>
                </div>
              </div>
              <div class="confirm-data-container">
                <b-form-checkbox
                  on
                  v-model="saveInformationForLater"
                  class="confirm-data"
                  >Save my information for future orders
                </b-form-checkbox>

                <b-form-checkbox
                  on
                  v-model="confirmChecked"
                  class="confirm-data"
                  value="me"
                  >I have double-checked my data
                </b-form-checkbox>

                <div
                  class="
                    w-100
                    my-2
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                >
                  <b-button block v-on:click="onPreview" class="preview-btn"
                    >Preview
                  </b-button>

                  <b-button
                    v-if="this.editMode == true"
                    class="continue-btn"
                    block
                    :disabled="!confirmChecked"
                    type="submit"
                    >Update
                  </b-button>
                  <b-button
                    v-else
                    class="continue-btn"
                    block
                    :disabled="!confirmChecked"
                    v-b-modal.modal-confirmation
                    >Continue
                  </b-button>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div>
                <div class="page-button-container">
                  <div
                    v-if="uniqueKey"
                    class="d-flex align-items-center justify-content-start"
                  >
                    <b-button
                      v-on:click="onChangePage(1)"
                      class="btn-page mx-1"
                      v-bind:class="{
                        'bp-active': activePage == 1,
                        'bp-normal': activePage != 1,
                      }"
                      ><span class="label-page">Page</span>
                      <span>1</span>
                    </b-button>
                    <b-button
                      v-on:click="onChangePage(2)"
                      class="btn-page mx-1"
                      v-bind:class="{
                        'bp-active': activePage == 2,
                        'bp-normal': activePage != 2,
                      }"
                      ><span class="label-page">Page</span>
                      <span>2</span>
                    </b-button>
                  </div>
                </div>
                <div class="loading" v-if="loadingPreview">
                  <b-spinner label="Loading..."></b-spinner>
                </div>
                <div
                  v-else
                  class="
                    preview-container
                    d-flex
                    align-items-start
                    justify-content-center
                  "
                >
                  <img
                    class="preview-img"
                    alt="Place holder"
                    :src="previewImage"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
    <b-modal id="modal-confirmation" title="" centered>
      <div
        class="loading d-flex align-items-center justify-content-center"
        v-if="loading"
      >
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <div v-else>
        <div class="modal-conf-header">
          <h3>Submit Your Request</h3>
          <b-button
            variant="light"
            class="modal-close"
            @click="onBackToEditClick()"
          >
            <span class="material-icons"> close </span>
          </b-button>
        </div>
        <b-form class="order-form" @submit="onSubmit" v-if="show">
          <b-form-group
            id="input-group-1"
            label="Order Quantity"
            label-for="quantity-option"
            class="text-dark"
          >
            <b-form-select
              v-model="orderDetail.quantity"
              :options="quantityOptions"
              id="quantity-option"
              required
            ></b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Shipping Address"
            label-for="address-option"
            class="mt-3"
            required
          >
            <b-form-select
              id="address-option"
              v-model="orderDetail.address"
              :options="addressOptions"
              required
            ></b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-3"
            label="Email"
            label-for="input-3"
            class="mt-3"
          >
            <b-form-input
              id="input-3"
              v-model="orderDetail.emailNotification"
              type="email"
              :disabled="this.userEmail ? true : false"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Delivery Speed"
            class="mt-2"
            v-if="hasSpeedOptions"
          >
            <b-form-radio-group
              id="delivery-speed"
              v-model="orderDetail.speed"
              name="radio-delivery-spped"
            >
              <b-form-radio
                v-for="speedOption in this.speedOptions"
                :key="speedOption.code"
                :value="speedOption.code"
                class="mt-2"
              >
                <div class="d-flex flex-column">
                  <span class="radio-label">{{ speedOption.name }}</span>
                  <span class="radio-sub-label">{{ speedOption.detail }}</span>
                </div>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <div
            class="
              border-top
              d-flex
              align-items-center
              justify-content-end
              w-100
              mt-5
              pt-4
            "
          >
            <b-button variant="light" @click="onBackToEditClick()"
              >Cancel
            </b-button>
            <b-button type="submit" class="order-submit-btn ggp-btn ml-2"
              >Submit
            </b-button>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: [
    "formdata",
    "submitUrl",
    "userId",
    "userEmail",
    "currentFormData",
    "editMode",
    "requestId",
    "currentOrderDetail"
  ],

  data() {
    return {
      form: {},
      orderDetail: {
        quantity: null,
        address: null,
        emailNotification: "",
        speed: null,
      },
      templateId: null,
      submitData: {},
      previewImage: "",
      preLoadPreviewImage: {
        page1: "",
        page2: "",
      },
      show: true,
      confirmChecked: false,
      saveInformationForLater: false,
      uniqueKey: "",
      activePage: 1,
      loadingPreview: false,
      addressOptions: [{ value: null, text: "Please select an option" }],
      quantityOptions: [{ value: null, text: "Please select an option" }],
      speedOptions: [],
      loading: false,
      success: false,
        userData: null
    };
  },
  mounted() {
    const data = JSON.parse(this.formdata);
    this.orderDetail.emailNotification = this.userEmail;

    if (this.editMode == true) {
      const prevFormData = JSON.parse(this.currentFormData);
      const currentOrderDetail = JSON.parse(this.currentOrderDetail);
      this.submitData = prevFormData;
        for (const [key, value] of Object.entries(prevFormData)) {
            this.submitData[key] = prevFormData[key]
        };
      this.orderDetail = currentOrderDetail;
    }

    this.form = data.form;
    this.previewImage = data.templatePreview;
    this.templateId = data.templateId;


    if( this.editMode != true && data.savedData != undefined){
        this.submitData = (data.savedData);
    }

    this.addressOptions = this.addressOptions.concat(
      data.addresses.map((a) => ({ value: a.id, text: a.name }))
    );

    this.quantityOptions = this.quantityOptions.concat(
      data.availableQuantity.map((a) => ({
        value: a,
        text: a,
      }))
    );

    this.speedOptions = data.speedOptions;
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.loading = true;
      this.enhanceFormDataWithDefaultValue();
      const request = {
        blockData: this.submitData,
        orderDetail: this.orderDetail,
        saveForLater: this.saveInformationForLater,
        templateId: this.templateId,
        userId: this.userId ? this.userId : undefined,
      };
      let submitUrl = this.editMode == true ? `/order/update` : `/order/send`;
      if (this.submitUrl) {
        submitUrl = this.submitUrl;
      }

      if (this.editMode == true) {
        request.id = this.requestId;
      }

      axios.post(submitUrl, request).then((res) => {
        this.loading = false;
        this.success = true;
        this.$bvModal.hide("modal-confirmation");
        this.orderDetail = {};
        if (res.status === 201 && this.editMode == true) {
          window.location.href = `/request/${this.requestId}`;
        }
      });
    },
    onPreview(event) {
      event.preventDefault();
      this.loadingPreview = true;
      this.enhanceFormDataWithDefaultValue();

      this.preLoadPreviewImage = {
        page1: "",
        page2: "",
      };
      axios
        .post(`/order/generate-preview`, {
          blockData: this.submitData,
          templateId: this.templateId,
          userId: this.userId,
        })
        .then((response) => {
          this.previewImage = response.data.result.image;
          this.uniqueKey = response.data.uniqueKey;
          this.activePage = 1;
          this.preLoadPreviewImage["page1"] = this.previewImage;
          this.loadingPreview = false;
        });
    },

    onChangePage(page) {
      this.loadingPreview = true;
      if (this.preLoadPreviewImage["page" + page]) {
        this.previewImage = this.preLoadPreviewImage["page" + page];
        this.activePage = page;
        this.loadingPreview = false;
      } else {
        axios
          .post(`/order/get-preview`, {
            page,
            uniqKey: this.uniqueKey,
            templateId: this.templateId,
            userId: this.userId,
          })
          .then((response) => {
            this.previewImage = response.data.result.image;
            this.activePage = page;
            this.preLoadPreviewImage["page" + page] = this.previewImage;
            this.loadingPreview = false;
          });
      }
    },

    onBackToEditClick() {
      this.$bvModal.hide("modal-confirmation");
      this.orderDetail = {
        emailNotification: this.userEmail
      };
    },

    enhanceFormDataWithDefaultValue(){
          if (this.form.dependent) {
              this.form.dependent.forEach((dp) => {
                  if (this.submitData[dp.depend]) {
                      this.submitData[dp.pageBlock.name] = dp.pageBlock.default;
                  } else if (dp.depend === null) {
                      this.submitData[dp.pageBlock.name] = dp.pageBlock.default;
                  }
              });
          }

          if(this.form.hidden){
              this.form.hidden.forEach((hd) => {
                  this.submitData[hd.name] = "";
              });
          }
      }
  },
  computed: {
    hasSpeedOptions: function () {
      return this.speedOptions.length > 0;
    },
  },
};
</script>
