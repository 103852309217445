<template>
  <div class="container my-5">
    <div class="row toolbar">
      <div class="col-md-6 col-sm-12">
        <div class="team-section-header">
          <h3>Team Members</h3>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 text-right">
        <b-link
          class="btn"
          :class="isMemeberSelected ? 'rm-btn-active' : 'rm-btn-default'"
          @click="onRemoveClick"
          >Remove</b-link
        >
        <a class="btn ggp-btn-blue" href="/team-invite">Add Member</a>
      </div>
    </div>
    <!--        <div class="filter row">-->
    <!--            <div class="col">-->
    <!--                <b-form-select v-model="inputSelected" :options="options"></b-form-select>-->
    <!--            </div>-->
    <!--            <div class="col">-->
    <!--                <b-form-select v-model="inputSelected" :options="options"></b-form-select>-->
    <!--            </div>-->
    <!--            <div class="col">-->
    <!--                <b-form-select v-model="inputSelected" :options="options"></b-form-select>-->
    <!--            </div>-->
    <!--            <div class="col">-->
    <!--                <b-form-input v-model="text" placeholder="Search"></b-form-input>-->
    <!--            </div>-->

    <!--        </div>-->
    <b-table
      :fields="fields"
      :items="items"
      selectable
      borderless
      striped
      outlined
      responsive="true"
      :select-mode="'multi'"
      @row-selected="onRowSelected"
      selected-variant="ggp-active"
      ref="members"
    >
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <i class="fa fa-check-square" aria-hidden="true"></i>
        </template>
        <template v-else>
          <i class="fa fa-square-o" aria-hidden="true"></i>
        </template>
      </template>

      <template #cell(name)="data">
        {{
          data.item.firstname
            ? data.item.firstname.charAt(0).toUpperCase() +
              data.item.firstname.slice(1)
            : ""
        }}
        {{
          data.item.lastname
            ? data.item.lastname.charAt(0).toUpperCase() +
              data.item.lastname.slice(1)
            : ""
        }}
      </template>
      <template #cell(active)="data">
        {{ data.item.active ? "Active" : "Inactive" }}
      </template>
      <template #cell(status)="data">
        <toggle-button
          color="#2BACE2"
          :value="Boolean(data.item.active)"
          :labels="false"
          :ref="`element${data.item.id}`"
          @change="enableToggle && onToggleMemberStatus($event, data.item)"
        />
      </template>
      <template #cell(edit)="data">
        <b-button
          variant="light"
          @click="onEditTeamMemember(data.item)"
          class="btn-edit"
        >
          <span class="material-icons"> edit </span>
        </b-button>
      </template>
    </b-table>

    <!-- edit team memeber modal -->
    <b-modal
      id="edit-member-modal"
      ref="edit-member-modal"
      no-close-on-backdrop
      centered
    >
      <div
        class="loading d-flex align-items-center justify-content-center"
        v-if="editMemberLoading"
      >
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <div v-else>
        <div class="modal-conf-header">
          <h3>Edit Member Details</h3>
          <b-button
            variant="light"
            class="modal-close"
            @click="onCloseEditMemberModal"
          >
            <span class="material-icons"> close </span>
          </b-button>
        </div>

        <b-form class="order-form" @submit="onSubmitEditMember">
          <b-alert :show="errorEditingMember" variant="danger"
            >Couldn't edit team member. Please try again</b-alert
          >
          <h6 class="pe-section-label">Basic Info</h6>

          <!-- Name -->
          <div
            class="
              d-flex
              align-items-center
              justify-content
              flex-column flex-md-row flex-lg-row
              mt-2
            "
          >
            <!-- First Name -->
            <b-form-group
              id="input-group-1"
              label="First Name *"
              label-for="firstName"
              class="flex-grow-1 mr-0 mr-md-2 mr-lg-2"
            >
              <b-form-input
                id="firstName"
                v-model="editMemberData.firstName"
                type="text"
                placeholder="Enter your first name"
                required
              ></b-form-input>
            </b-form-group>

            <!-- Last Name -->
            <b-form-group
              id="input-group-2"
              label="Last Name *"
              label-for="lastName"
              class="flex-grow-1 ml-md-2 ml-lg-2"
            >
              <b-form-input
                id="lastName"
                v-model="editMemberData.lastName"
                type="text"
                placeholder="Enter your last name"
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <!-- Email -->
          <b-form-group
            id="input-group-3"
            label="Email Address *"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="editMemberData.email"
              type="email"
              placeholder="Enter your email"
              required
            ></b-form-input>
          </b-form-group>

          <!-- Phone Number -->
          <b-form-group
            id="input-group-4"
            label="Phone Number"
            label-for="phoneNumber"
            class="mt-3"
          >
            <b-form-input
              id="phoneNumber"
              v-model="editMemberData.phoneNumber"
              type="tel"
              placeholder="Enter your phone number"
            ></b-form-input>
          </b-form-group>

          <!-- Reset Credentials -->
          <h6 class="header-reset-cred mt-5">Reset Credentials</h6>
          <p class="sub-reset-cred mt-1">
            The rest password will be sent to
            <span>{{ editMemberData.email }}</span>
          </p>

          <!-- Input reset cred -->
          <b-form-group
            id="input-group-5"
            label=""
            label-for="resetCredential"
            class="mt-3 pb-3"
          >
            <b-form-input
              id="resetCredential"
              v-model="resetCredential"
              type="text"
              placeholder="Enter new password"
            ></b-form-input>

            <p class="suggestedPassword mt-2">
              Click to set suggested password instead
              <span @click="onSetGenereatedPassword">{{
                generatedPassword
              }}</span>
            </p>
          </b-form-group>

          <div
            class="
              d-flex
              align-items-center
              justify-content-end
              w-100
              mt-3
              pt-3
              border-top
            "
          >
            <b-button variant="light" @click="onCloseEditMemberModal"
              >Cancel
            </b-button>
            <b-button type="submit" class="edit-submit-btn ml-2"
              >Save
            </b-button>
          </div>
        </b-form>
      </div>
    </b-modal>

    <!-- General loading progress -->
    <div
      class="
        d-flex
        align-items-center
        justify-content-center
        position-fixed
        main-progress
      "
      v-if="showMainProgress"
    >
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </div>
</template>

<script>
import { getMemberById, removeMember } from "../../services/TeamService";
import generator from "generate-password";

export default {
  data() {
    return {
      fields: [
        "selected",
        {
          key: "email",
          label: "Email",
          sortable: true,
          class: "font-weight-bold",
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "roles",
          label: "Group",
          sortable: true,
        },
        {
          key: "status",
          label: "Active",
          sortable: false,
          class: "is-active-toggle",
        },
        {
          key: "edit",
          label: "",
          sortable: false,
          class: "edit-btn-cont",
        },
      ],
      selected: [],
      items: [],
      token: JSON.parse(this.ggpdata).token,
      options: [
        { value: null, text: "Please select an option" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        {
          value: { C: "3PO" },
          text: "This is an option with object value",
        },
      ],
      inputSelected: null,
      currentUserId: null,
      errorEditingMember: false,
      editMemberLoading: false,
      showMainProgress: false,
      enableToggle: true, // flag controls wheather a change event is triggered
      editMemberData: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      },
      resetCredential: "",
    };
  },
  props: ["ggpdata"],
  mounted() {
    const ggpData = JSON.parse(this.ggpdata);
    this.currentUserId = ggpData.current_user;
    this.updateMember();
  },
  methods: {
    updateMember() {
      getMemberById(this.currentUserId).then((res) => {
        this.items = res.data.data;
        console.log(this.items);
      });
    },
    onRowSelected(items) {
      this.selected = items;
    },
    onRemoveClick() {
      const removeParam = { members: this.selected.map((member) => member.id) };
      removeMember(removeParam).then((res) => {
        this.$swal("Removed !", "User has been marked as inactive.", "success");
        this.updateMember();
      });
    },
    onSubmitEditMember(event) {
      event.preventDefault();

      this.editMemberLoading = true;
      this.errorEditingMember = false;

      const updateTeamMemberUrl = "/team/update-member";

      axios
        .put(updateTeamMemberUrl, {
          profileDetail: this.editMemberData,
          resetCredential: this.resetCredential,
        })
        .then(() => {
          this.editMemberLoading = false;
          this.onCloseEditMemberModal();
          this.$swal(
            "Team Member Update",
            "Team member's profile has been successfuly updated",
            "success"
          );
        })
        .catch((e) => {
          console.log(e);
          this.editMemberLoading = false;
          this.errorEditingMember = true;
        });
    },
    onCloseEditMemberModal() {
      this.$refs["edit-member-modal"].hide();
    },
    onEditTeamMemember(item) {
      this.editMemberData.id = item.id;
      this.editMemberData.firstName = item.firstname;
      this.editMemberData.lastName = item.lastname;
      this.editMemberData.email = item.email;
      this.editMemberData.phoneNumber = item.phoneNumber;
      this.errorEditingMember = false;
      this.resetCredential = "";

      this.$refs["edit-member-modal"].show();
    },
    onToggleMemberStatus(event, item) {
      this.showMainProgress = true;
      this.enableToggle = false;

      const updateMemberStatusURL = "/team/update-member-active";

      const request = {
        id: item.id,
        active: !item.active,
      };

      axios
        .put(updateMemberStatusURL, request)
        .then((res) => {
          let updatedMember = res.data.data.member;
          // find and update member
          this.items = this.items.map((member) =>
            member.id === updatedMember.id
              ? { ...member, active: updatedMember.active }
              : member
          );

          // refresh table
          this.$refs.members.refresh();

          this.showMainProgress = false;
          this.enableToggle = true;
        })
        .catch(() => {
          this.showMainProgress = false;

          this.$refs[`element${item.id}`].$el.click();
          this.enableToggle = true;

          this.$swal(
            "Team Member Update",
            "Error updating team member status. Please try again",
            "error"
          );
        });
    },
    onSetGenereatedPassword() {
      this.resetCredential = this.generatedPassword;
    },
  },
  computed: {
    isMemeberSelected() {
      return this.selected.length > 0;
    },
    generatedPassword() {
      return generator.generate({
        length: 10,
        numbers: true,
      });
    },
  },
};
</script>
