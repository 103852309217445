<template>
  <div class="request_section container my-5">
    <!--  Header  -->
    <div class="header">
      <div class="header-title-content">
        <h3 class="">Print Requests</h3>
        <div class="total-requests" v-if="ispage">
          <span> {{ totalRequestCount }} </span> Total Request{{
            totalRequestCount == 1 ? "" : "s"
          }}
        </div>
      </div>

      <a href="/requests" class="view-all" v-if="!ispage"
        >View All
        <span class="mdi mdi-arrow-top-right"></span>
      </a>
    </div>

    <!-- Content -->
    <div
      v-if="requests.length > 0"
      class="accordion ggp-accordion"
      role="tablist"
    >
      <b-card
        v-for="(request, index) in requests"
        v-bind:key="request.id"
        no-body
        class="my-4"
      >
        <div class="ggp-accordion-div row" v-b-toggle="'accordion-' + index">
          <!-- addresss name -->
          <div class="col-md-4 address-text">
            <i class="mdi mdi-map-marker" aria-hidden="true"></i>
            {{ request.name }}
          </div>

          <!-- request number -->
          <div class="col-md-3 main-text">
            <span class="request-count">
              {{ request.openRequestCount }}
            </span>
            {{ " Open Request" + (request.openRequestCount > 1 ? "s" : "") }}
          </div>

          <!-- add to cart button -->
          <div
            class="
              col-md-4
              d-flex
              align-items-center
              justify-content-start
              mt-3 mt-md-0
            "
          >
            <template v-if="isAdmin && request.openRequestCount > 0">
              <b-button
                variant="danger"
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  flex-grow-1 flex-md-grow-0
                "
                @click="onRejectModalReveal(request.id)"
              >
                Reject
              </b-button>
              <b-button
                class="btn-add-to-cart flex-grow-1 flex-md-grow-0"
                :class="{
                  'ggp-btn-blue': true,
                  loading: loading,
                }"
                v-on:click.stop="onAddToCartClick(request.id)"
              >
                <i class="mdi mdi-plus" aria-hidden="true"></i>
                Add to cart
              </b-button>
            </template>
          </div>

          <!-- card control -->
          <div class="when-closed col-md-1">
            <i
              class="mdi mdi-chevron-down accordion-chevron"
              aria-hidden="true"
            ></i>
          </div>
          <div class="when-open col-md-1">
            <i
              class="mdi mdi-chevron-up accordion-chevron"
              aria-hidden="true"
            ></i>
          </div>
        </div>
        <b-collapse
          :id="'accordion-' + index"
          :accordion="'accordion-' + index"
          role="tabpanel"
          my-3
        >
          <b-card-body class="table-container">
            <b-table
              outlined
              :items="request.displayRequests"
              :fields="fields"
              :select-mode="selectMode"
              :ref="request.id"
              hover
              responsive="md"
              selectable
              @row-clicked="onRowClicked"
            >
              <template #cell(selected)="{ rowSelected, item }">
                <template v-if="item.status !== 'Waiting Approval'"> </template>
                <template v-else-if="rowSelected">
                  <i class="fa fa-check-square" aria-hidden="true"></i>
                </template>
                <template v-else>
                  <i class="fa fa-square-o" aria-hidden="true"></i>
                </template>
              </template>
              <template #cell(requestId)="data">
                <a :href="'/request/' + data.item.id"> {{ data.value }}</a>
              </template>
              <template #cell(status)="data">
                <div
                  :class="
                    'ggp-text-' + data.value.toLowerCase().replace(/\s/g, '')
                  "
                >
                  {{ data.value }}
                </div>
              </template>
            </b-table>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <div v-else>No print requests</div>

    <!-- add to cart modal -->
    <b-modal id="add-to-cart-modal">
      <div class="loading" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <div v-else>
        <div class="success-view" v-if="success">
          <i class="fa fa-check-circle" aria-hidden="true"></i>
          <p class="header">{{ modalMessage }}</p>
          <a
            target="_blank"
            :href="magentoHost + '/checkout/cart/'"
            class="btn ggp-btn-blue"
            >View Cart</a
          >
        </div>
        <div class="success-view" v-else>
          <i class="fa fa-times" aria-hidden="true"></i>
          <p class="header">{{ modalMessage }}</p>
          <a
            target="_blank"
            :href="magentoHost + '/checkout/cart/'"
            class="btn ggp-btn-blue"
            >View Cart</a
          >
        </div>
      </div>
    </b-modal>

    <!-- reject modal -->
    <b-modal
      ref="reject-request-modal"
      title="Reject Request"
      id="reject-request-modal"
      hide-footer
      centered 
    >
      <p class="mb-2 request-ids">
        Please state the reason why you want to reject the request(s)
        <template v-for="(selectedItem, index) in rejectionRequests">
          <span class="sr-id" :key="selectedItem.id">
            {{ selectedItem.requestId }}
          </span>
          <template v-if="index < rejectionRequests.length - 1">,</template>
        </template>
      </p>

      <div class="mt-1">
        <b-form-textarea
          id="input-rejection-reason"
          v-model="rejectionReason"
          placeholder="Your reason"
          no-resize
          rows="5"
        ></b-form-textarea>
      </div>

      <p v-if="invalidRejection" class="mt-1 text-danger err-invalid-rejection">
        Please state your reason and select the confirmation checkbox to
        continue
      </p>

      <b-form-checkbox
        class="mt-2"
        v-model="rejectionStatus"
        name="rejection-conf"
        value="confirmed"
        unchecked-value="not_confirmed"
      >
        I’m sure I want to reject the request(s)
      </b-form-checkbox>

      <div class="mt-3 d-flex align-items-center justify-content-center">
        <b-button
          class="mr-1 flex-grow-1"
          @click="$bvModal.hide('reject-request-modal')"
          >Cancel</b-button
        >
        <b-button
          class="ml-1 flex-grow-1"
          variant="danger"
          v-on:click.stop="onRejectClick(rejectionRequestId)"
          >Reject</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
const WAITING_APPROVAL = "Waiting Approval";
export default {
  Constant: {},
  data() {
    return {
      success: false,
      requests: [],
      fields: [
        {
          key: "selected",
          label: "",
        },
        {
          key: "requestId",
          label: "Request ID",
        },
        "requestDate",
        "orderedBy",
        "quantity",
        {
          key: "status",
          label: "Status",
        },
      ],
      selected: [],
      selectMode: "multi",
      loading: false,
      modalMessage: "",
      magentoHost: "",
      isAdmin: false,
      totalRequestCount: 0,
      invalidRejection: false,
      rejectionReason: "",
      rejectionStatus: "not_confirmed",
      rejectionRequestId: "",
    };
  },
  props: {
    ggpdata: {
      type: String,
      required: true,
    },
    ispage: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const data = JSON.parse(this.ggpdata);
    this.requests = data.requests;
    this.magentoHost = data.magentoHost;
    this.isAdmin = data.isAdmin;
    this.totalRequestCount = data.totalOpenRequestCount;
  },
  methods: {
    onRowClicked(item, index, event) {
      if (item.status === WAITING_APPROVAL) {
        this.toggleSelectedItem(item);
      }
      //Review i
    },

    // Todo: refactor
    toggleSelectedItem(item) {
      if (
        this.selected[item.address_id] &&
        this.selected[item.address_id].length > 0 &&
        this.selected[item.address_id].find((it) => it.id === item.id) !==
          undefined
      ) {
        this.selected[item.address_id] = this.selected[item.address_id].filter(
          (it) => it.id !== item.id
        );
      } else {
        if (!this.selected[item.address_id]) {
          this.selected[item.address_id] = [];
        }
        this.selected[item.address_id].push(item);
      }
    },

    onAddToCartClick(address_id) {
      if (!this.selected[address_id] || !this.selected[address_id].length) {
        alert("Please select at least one item");
        return;
      }
      this.loading = true;
      this.$root.$emit("bv::show::modal", "add-to-cart-modal", "#btnShow");
      const selectRequests = this.selected[address_id];
      axios
        .post(`/request/addToCart`, { requests: selectRequests })
        .then((response) => {
          if (response.data.result === "success") {
            this.success = true;
            this.modalMessage = "The request added.";
          } else {
            if (response.data.error.length > 0) {
              if (response.data.message == "ITEMS_ALREADY_EXISTED") {
                throw new Error("Request already added to cart");
              }
            }
            throw new Error(
              "Something went wrong. Please contact administrator."
            );
          }

          this.loading = false;
        })
        .catch((err) => {
          if (err) {
            this.modalMessage = err.message;
          }
          this.loading = false;
        });
    },


    onRejectModalReveal(address_id) {
      if (!this.selected[address_id] || !this.selected[address_id].length) {
        alert("Please select at least one item");
        return;
      }

      this.rejectionRequestId = address_id;

      this.$refs["reject-request-modal"].show();
    },

    onRejectClick(address_id) {
      if (
        this.rejectionReason === "" ||
        this.rejectionStatus === "not_confirmed"
      ) {
        this.invalidRejection = true;
        return;
      } else {
        this.$refs["reject-request-modal"].hide();
      }

      this.loading = true;
      this.$root.$emit("bv::show::modal", "add-to-cart-modal", "#btnShow");
      const selectRequests = this.selected[address_id];
      axios
        .post(`/request/reject`, {
          requests: selectRequests,
          rejectReason: this.rejectionReason,
        })
        .then((response) => {
          if (response.data.code === "Success") {
            this.success = true;
          }
          if (response.data.error && response.data.error.length > 0) {
            throw new Error(response.data.error);
          }

          this.fetchRequests();
          this.modalMessage = response.data.message;
          this.loading = false;
        })
        .catch((err) => {
          if (err)
            this.modalMessage = err.response
              ? err.response.data.message
              : err.message;
          this.loading = false;
        });

      // reset values
      this.rejectionReason = "";
      this.invalidRejection = false;
      this.rejectionStatus = "not_confirmed";
    },

    fetchRequests() {
      axios
        .post(`/request/fetchRequests`, {})
        .then((response) => {
          if (response.data.code === "Success") {
            this.requests = response.data.data.requests;
            this.totalRequestCount = response.data.data.totalOpenRequestCount;
          }
          if (response.data.error.length > 0) {
            throw new Error(response.data.error);
          }
        })
        .catch((err) => {});
    },
  },
  computed: {
    rejectionRequests() {
      return this.selected[this.rejectionRequestId];
    },
  },
};
</script>
