<template>
    <div class="team-invite-section container">
        <div class="loading" v-if="loading">
            <b-spinner label="Loading..."></b-spinner>
        </div>
        <div class="success-view" v-else-if="success">
            <i class="fa fa-check-circle" aria-hidden="true"></i>
            <p class="header">The invitation has been sent to :</p>
            <ul id="example-1">
                <li v-for="item in successData">
                    {{ item }}
                </li>
            </ul>
            <b-link href="/team" class="btn ggp-btn-blue">See Team Members</b-link>
            <b-link @click="inviteMore" class="btn ggp-btn-blue" href="#">Invite More</b-link>
        </div>
        <div v-else>
            <div class="justify-content-center row">
                <p class="header ggp-lightblue-text">Invite New Team Members</p>
                <p>Please invite new team members to the print portal by entering each email followed by pressing
                    "Enter" one by one.</p>
            </div>
            <div class="text-area-container row justify-content-center">
                <div class="personal-addteam-section">
                    <p class="sub-header">Add Team Member to this Portal</p>
                    <div @click="focusInput" class="text-area">
                        <vue-tags-input
                            ref="email"
                            placeholder="Add Email"
                            v-model="tag"
                            :tags="tags"
                            @tags-changed="newTags => tags = newTags"
                            @before-adding-tag="beforeAddTag"
                        />
                    </div>
                </div>
                <div class="personal-message-section">
                    <p class="sub-header">Personal Message</p>
                    <textarea v-model="inviteMessage" class="text-area" type="text"/>
                </div>
                <div class="invite-button-section">
                    <b-link @click="onSendInvitation" class="btn btn-block ggp-btn-blue" href="#">Send Invitation
                    </b-link>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

    import VueTagsInput from '@johmun/vue-tags-input'
    import { inviteMembers } from '../../services/TeamService'

    export default {
        data () {
            return {
                tag: '',
                tags: [],
                handlers: [],
                inviteMessage: 'Hi,\n\nPlease click the link below to register for our new PMT (Print Management Tool)! Registering will allow you to order business cards and other print products with ease.\n\nRegards,\nAdmin-Team',
                loading: false,
                success: false,
                successData: []
            }
        },
        props: ['ggpdata'],
        mounted () {

        },
        methods: {
            beforeAddTag (obj) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                if (!re.test(String(obj.tag.text).toLowerCase())) {
                    alert('Please enter valid email address')
                    return
                }

                if (this.tags.map(email => (email.text)).includes(String(obj.tag.text))) {
                    alert('Duplicate email address')
                    return
                }

                obj.addTag()
            },
            onSendInvitation () {
                if (this.validateRequest()) {
                    this.loading = true
                    inviteMembers(this.tags.map((email) => (email.text)), this.inviteMessage.replaceAll('\n', '<br>')).then((res) => {
                        this.success = true
                        this.successData = res.data.data
                    }).catch((err) => {
                        this.makeToast(JSON.stringify(err.response.data))
                    }).finally(() => {
                        this.loading = false
                    })
                }

            },

            validateRequest () {
                if (this.tags.length === 0 || !this.inviteMessage) {
                    this.makeToast('Please enter all required fields')
                    return false
                }
                return true
            },

            makeToast (message) {
                console.log('making toast')
                this.$bvToast.toast(message, {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 3000,
                    toaster: 'b-toaster-bottom-right',
                })
            },

            focusInput () {

                this.$nextTick(() => {
                    console.log(this.$refs.email.$refs.newTagInput)
                    this.$refs.email.$refs.newTagInput.focus()
                })

            },

            inviteMore () {
                this.success = false
                this.successData = []
                this.tag = ''
                this.tags = []
            }

        }
    }
</script>
