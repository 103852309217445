<template>
    <div id="profile-edit" class="container my-5">
        <div class="d-flex flex-column profile-edit-container mx-auto">
            <div class="pe-header d-flex w-100 m-auto">
                <h5>Profile</h5>
            </div>

            <!-- Basic Info -->
            <b-form
                class="profile-form d-flex flex-column w-100 mx-auto mt-2"
                @submit="onProfileSubmit"
            >
                <h6 class="pe-section-label">Basic Info</h6>

                <!-- Name -->
                <div
                    class="
            d-flex
            align-items-center
            justify-content
            flex-column flex-md-row flex-lg-row
            mt-2
          "
                >
                    <!-- First Name -->
                    <b-form-group
                        id="input-group-1"
                        label="First Name *"
                        label-for="firstName"
                        class="flex-grow-1 mr-0 mr-md-2 mr-lg-2"
                    >
                        <b-form-input
                            id="firstName"
                            v-model="userData.firstName"
                            type="text"
                            placeholder="Enter your first name"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <!-- Last Name -->
                    <b-form-group
                        id="input-group-2"
                        label="Last Name *"
                        label-for="lastName"
                        class="flex-grow-1 ml-md-2 ml-lg-2"
                    >
                        <b-form-input
                            id="lastName"
                            v-model="userData.lastName"
                            type="text"
                            placeholder="Enter your last name"
                            required
                        ></b-form-input>
                    </b-form-group>
                </div>

                <!-- Email -->
                <b-form-group
                    id="input-group-3"
                    label="Email Address *"
                    label-for="email"
                    class="mt-3"
                >
                    <b-form-input
                        id="email"
                        v-model="userData.email"
                        type="email"
                        placeholder="Enter your email"
                        required
                    ></b-form-input>
                </b-form-group>

                <!-- Phone Number -->
                <b-form-group
                    id="input-group-4"
                    label="Phone Number"
                    label-for="phoneNumber"
                    class="mt-3"
                >
                    <b-form-input
                        id="phoneNumber"
                        v-model="userData.phoneNumber"
                        type="tel"
                        placeholder="Enter your phone number"
                    ></b-form-input>
                </b-form-group>

                <div class="d-flex align-items-center justify-content-end w-100 mt-3">
                    <b-button type="submit" class="edit-submit-btn ml-2">Save</b-button>
                </div>
            </b-form>

            <!-- Reset  Credentials  -->
            <b-form
                class="credentials-form d-flex flex-column w-100 mx-auto mt-4"
                @submit="onResetCredentials"
            >
                <h6 class="pe-section-label">Credentials</h6>

                <!-- Current Password -->
                <b-form-group
                    id="input-group-5"
                    label="Current Password *"
                    label-for="currentPassword"
                    class="d-flex flex-column mt-2"
                >
                    <b-form-input
                        id="currentPassword"
                        v-model="passwordReset.currentPassword"
                        type="password"
                        placeholder="Enter current password"
                        required
                    ></b-form-input>
                    <p
                        class="text-danger m-0 pt-1"
                        v-if="passwordValidation.wrongCurrentPass"
                    >
                        The old password you have entered is incorrect
                    </p>
                </b-form-group>

                <!-- New Password -->
                <b-form-group
                    id="input-group-6"
                    label="New Password *"
                    label-for="newPassword"
                    class="mt-3 d-flex flex-column"
                >
                    <b-form-input
                        id="newPassword"
                        v-model="passwordReset.newPassword"
                        type="password"
                        placeholder="Enter new password"
                        required
                    ></b-form-input>

                    <p
                        class="text-danger m-0 pt-1"
                        v-if="passwordValidation.passwordMatchError"
                    >
                        Passwords do not match
                    </p>
                </b-form-group>

                <!-- Confirm Password -->
                <b-form-group
                    id="input-group-7"
                    label="Confirm Password"
                    label-for="confirmPassword"
                    class="mt-3 d-flex flex-column"
                >
                    <b-form-input
                        id="confirmPassword"
                        v-model="passwordReset.confirmPassword"
                        type="password"
                        placeholder="Confirm password"
                        required
                    ></b-form-input>

                    <p
                        class="text-danger m-0 pt-1"
                        v-if="passwordValidation.passwordMatchError"
                    >
                        Passwords do not match
                    </p>
                </b-form-group>

                <div class="d-flex align-items-center justify-content-end w-100 mt-3">
                    <b-button type="submit" class="edit-submit-btn ml-2"
                    >Reset Password
                    </b-button>
                </div>
            </b-form>
        </div>

        <div
            class="
        loading-cont
        position-fixed
        d-flex
        align-items-center
        justify-content-center
      "
            v-if="isLoading"
        >
            <b-spinner label="Loading..."></b-spinner>
        </div>
    </div>
</template>

<script>
    import bcrpytJs from "bcryptjs";

    export default {
        props: ["user"],
        data() {
            return {
                data: {},
                userData: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    phoneNumber: "",
                },
                passwordReset: {
                    currentPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                },
                prevPasswordHash: null,
                passwordValidation: {
                    wrongCurrentPass: false,
                    passwordMatchError: false,
                },
                isLoading: false,
            };
        },
        mounted() {
            // dummy data
            this.data = JSON.parse(this.user);

            // populate user data
            this.populateUserData();

            this.prevPasswordHash = this.data.password_hash;
        },
        methods: {
            populateUserData() {
                this.userData.firstName = this.data.user.firstName;
                this.userData.lastName = this.data.user.lastName;
                this.userData.email = this.data.user.email;
                this.userData.phoneNumber = this.data.user.phoneNumber;
            },
            resetPasswordValidation() {
                this.passwordValidation.wrongCurrentPass = false;
                this.passwordValidation.passwordMatchError = false;
            },
            onProfileSubmit(e) {
                e.preventDefault();
                this.isLoading = true;
                const updateProfileUrl = "/profile/update";

                axios
                    .put(updateProfileUrl, {
                        userId: this.data.user.id,
                        profileDetail: this.userData,
                    })
                    .then(() => {
                        console.log("post");
                        this.isLoading = false;
                        this.$swal(
                            "Profile Update",
                            "Your info has been successfuly updated",
                            "success"
                        );
                    })
                    .catch(() => {
                        this.isLoading = false;
                        this.$swal(
                            "Profile Update",
                            "Error updating your profile. Please try again",
                            "error"
                        );
                    });
            },
            onResetCredentials(e) {
                e.preventDefault();
                this.resetPasswordValidation();
                if (!bcrpytJs.compareSync(this.passwordReset.currentPassword, this.prevPasswordHash)) {
                    this.passwordValidation.wrongCurrentPass = true;
                    return;
                }


                if (
                    this.passwordReset.newPassword !==
                    this.passwordReset.confirmPassword
                ) {
                    this.passwordValidation.passwordMatchError = true;
                    return;
                }

                this.isLoading = true;
                const resetPasswordUrl = "/profile/reset";

                axios
                    .put(resetPasswordUrl, {
                        userId: this.data.user.id,
                        password: this.currentHashPassword(),
                    })
                    .then(() => {
                        this.isLoading = false;
                        this.$swal(
                            "Password Rest",
                            "Your password has been successfuly updated",
                            "success"
                        );
                    })
                    .catch(() => {
                        this.isLoading = false;
                        this.$swal(
                            "Password Rest",
                            "Error reseting your password. Please try again",
                            "error"
                        );
                    });
            },

            currentHashPassword(){
                let salt = bcrpytJs.genSaltSync(10);
                return bcrpytJs.hashSync(this.passwordReset.newPassword,salt);
            }

        },
    };
</script>
