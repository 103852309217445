<template>
  <div id="request-detail" class="container my-5">
    <div class="row ggp-bread-crum px-2">
      Open Print Request > Print Request Details
    </div>

    <div class="row mb-4 px-2">
      <h3 class="page_header">Print Request Details</h3>
    </div>

    <div class="row">
      <div class="col-lg-7 col-md-6 col-sm-12" id="request-detial-list">
        <!-- Request ID -->
        <div class="d-flex flex-column">
          <span class="rdl-label">Request ID</span>
          <span class="rdl-value request-id mt-1">{{
            data.request.requestId
          }}</span>
        </div>

        <!-- Request Date & Quantity-->
        <div class="w-100 d-flex flex-column flex-lg-row">
          <div class="d-flex flex-column mt-4 w-50">
            <span class="rdl-label">Request Date</span>
            <span class="rdl-value mt-1">{{ data.request.requestDate }}</span>
          </div>

          <div class="d-flex flex-column mt-4 flex-grow-1">
            <span class="rdl-label">Quantity</span>
            <span class="rdl-value mt-1">{{ data.request.quantity }}</span>
          </div>
        </div>

        <!-- Delivery Speed & Status-->
        <div class="w-100 d-flex flex-column flex-lg-row">
          <div class="d-flex flex-column mt-4 w-50">
            <span class="rdl-label">Delivery Speed</span>
            <span class="rdl-value mt-1">{{ selectedSpeedOption }}</span>
          </div>

          <div class="d-flex flex-column mt-4 flex-grow-1">
            <span class="rdl-label">Status</span>
            <span class="rdl-value mt-1">{{ data.request.status }}</span>
          </div>
        </div>

        <!-- Configuration -->
        <div class="d-flex flex-column mt-4">
          <span class="rdl-label">Configuration</span>
          <span class="rdl-value mt-1">{{ data.request.description }}</span>
        </div>

        <div class="alert-banner info my-3">
          <i class="mdi mdi-information-outline"></i>
          <span class="alert-text"
            >Please Contact our staff if you would like to change the
            configuration.</span
          >
        </div>

        <hr />

        <!-- Notification Email -->
        <div class="d-flex flex-column mt-4">
          <span class="rdl-label">Notification Email</span>
          <span class="rdl-value mt-1">
            <a class="email-link" :href="emailLink" target="_blank">{{
              data.request.emailForNotification
            }}</a>
          </span>
        </div>

        <!-- Shipping Address -->
        <div class="d-flex flex-column mt-4">
          <span class="rdl-label">Shipping Address</span>
          <span class="rdl-value mt-1">
            {{ data.request.address_name }}
          </span>
        </div>

        <!-- Edit button -->
        <b-button v-b-modal.edit-request-modal class="mt-4" ok-title="Submit"
          >Edit Details</b-button
        >
      </div>

      <div
        id="request-preview"
        class="col-lg-4 col-md-6 col-sm-12 mt-4 mt-md-0 offset-lg-1"
      >
        <div class="loading h-100" v-if="previewLoading">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div v-else-if="data.request.previews !== null">
          <img :src="data.request.previews[0].result.image" />
          <img :src="data.request.previews[1].result.image" />
        </div>
        <div v-else>
          <div class="alert-banner warning mb-3">
            <i class="mdi mdi-alert-outline"></i>
            <span class="alert-text">Preview not available.</span>
          </div>

          <div
            class="
              d-flex
              align-items-center
              justify-content-center
              preview-placeholder
            "
          >
            <i class="mdi mdi-image"></i>
          </div>
        </div>

        <div
          class="w-100 d-flex align-items-center justify-content-start mt-3"
        >
          <b-link
            :href="data.request.downloadUrl"
            class="block btn btn-view-pdf mr-2"
            >View PDF
          </b-link>

          <b-link :href="'/request/edit-order/' + data.request.id" class="block btn btn-change-pdf">Change PDF</b-link>
        </div>
      </div>
    </div>

    <!-- edit request modal -->
    <b-modal
      id="edit-request-modal"
      ref="edit-request-modal"
      no-close-on-backdrop
      centered
    >
      <div
        class="loading d-flex align-items-center justify-content-center"
        v-if="editOrderLoading"
      >
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <div v-else>
        <div class="modal-conf-header">
          <h3>Edit Request Details</h3>
          <b-button
            variant="light"
            class="modal-close"
            @click="onCloseEditRequestModal"
          >
            <span class="material-icons"> close </span>
          </b-button>
        </div>

        <b-form class="order-form" @submit="updateRequest">
          <b-alert :show="errorEditingOrder" variant="danger"
            >Couldn't edit order. Please try again</b-alert
          >

          <b-form-group
            id="input-group-1"
            label="Order Quantity"
            label-for="quantity-option"
            class="text-dark"
          >
            <b-form-select
              v-model="orderDetail.quantity"
              :options="quantityOptions"
              id="quantity-option"
              required
            ></b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Shipping Address"
            label-for="address-option"
            class="mt-3"
          >
            <b-form-select
              id="address-option"
              v-model="orderDetail.address"
              :options="addressOptions"
              required
            ></b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-3"
            label="Email"
            label-for="input-3"
            class="mt-3"
          >
            <b-form-input
              id="input-3"
              v-model="orderDetail.emailNotification"
              type="email"
              placeholder="Enter email"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Delivery Speed" class="mt-2">
            <b-form-radio-group
              id="delivery-speed"
              v-model="orderDetail.speed"
              name="radio-delivery-speed"
            >
              <b-form-radio
                v-for="speedOption in this.speedOptions"
                :key="speedOption.code"
                :value="speedOption.code"
                class="mt-2"
                required
              >
                <div class="d-flex flex-column">
                  <span class="radio-label">{{ speedOption.name }}</span>
                  <span class="radio-sub-label">{{ speedOption.detail }}</span>
                </div>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <div
            class="
              border-top
              d-flex
              align-items-center
              justify-content-end
              w-100
              mt-5
              pt-4
            "
          >
            <b-button variant="light" @click="onCloseEditRequestModal"
              >Cancel</b-button
            >
            <b-button type="submit" class="edit-submit-btn ml-2"
              >Save
            </b-button>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["ggpdata"],
  data() {
    return {
      data: {},
      orderDetail: {
        quantity: null,
        address: null,
        emailNotification: "",
        speed: null,
      },
      addressOptions: [{ value: null, text: "Please select an option" }],
      quantityOptions: [{ value: null, text: "Please select an option" }],
      speedOptions: [],
      previewLoading: false,
      editOrderLoading: false,
      errorEditingOrder: false,
    };
  },
  mounted() {
    this.data = JSON.parse(this.ggpdata);


    // parse and populate form data
    this.populateFormData();

    // populate details
    this.populateOrderDetials();
  },
  methods: {
    populateFormData() {
      const addresses = this.data.request.addresses;
      const quantities = this.data.request.availableQuantity;
      const speedOptions = this.data.request.speedOptions;

      this.addressOptions = this.addressOptions.concat(
        addresses.map((a) => ({ value: a.id, text: a.name }))
      );

      this.quantityOptions = this.quantityOptions.concat(
        quantities.map((a) => ({
          value: a,
          text: a,
        }))
      );

      this.speedOptions = speedOptions;
    },
    populateOrderDetials() {
      this.orderDetail.quantity = this.data.request.quantity;
      this.orderDetail.emailNotification =
        this.data.request.emailForNotification;

      this.orderDetail.address = this.data.request.address_id;

      const selectedSpeedOption = this.data.request.speed;
      this.orderDetail.speed = selectedSpeedOption
        ? selectedSpeedOption
        : this.speedOptions[0].code;
    },
    updateRequest(event) {
      event.preventDefault();

      this.editOrderLoading = true;
      this.errorEditingOrder = false;

      const updateUrl = "/order/update";
      const request = {
        id: this.data.request.id,
        orderDetail: this.orderDetail,
      };
      axios
        .post(updateUrl, request)
        .then((res) => {
          this.$refs["edit-request-modal"].hide();
          this.$swal(
            "Edit Request",
            "Successfuly edited selected request",
            "success"
          ).then(() => window.location.reload());
        })
        .catch(() => {
          this.editOrderLoading = false;
          this.errorEditingOrder = true;
        });
    },
    onCloseEditRequestModal() {
      this.$refs["edit-request-modal"].hide();
    },
  },
  computed: {
    emailLink() {
      const email = this.data.request.emailForNotification || "-";
      return `mailto:${email}`;
    },
    selectedSpeedOption() {
      const selectedSpeedOption = this.data.request.speed;

      return selectedSpeedOption
        ? selectedSpeedOption
        : this.speedOptions[0].name;
    },
  },
};
</script>
