/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import {
    BootstrapVue,
    IconsPlugin,
    ToastPlugin
} from 'bootstrap-vue'

require('./bootstrap')
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSweetalert2 from 'vue-sweetalert2'
import ToggleButton from 'vue-js-toggle-button'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'animate.css';


window.Vue = require('vue')

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('dashboard-component', require('./components/DashboardComponent.vue').default)
Vue.component('team-component', require('./components/team/TeamComponent.vue').default)
Vue.component('template-component', require('./components/template/TemplateComponent.vue').default)
Vue.component('team-invite-component', require('./components/team/TeamInviteComponent.vue').default)
Vue.component('order-component', require('./components/order/OrderComponent.vue').default)
Vue.component('requests-component', require('./components/requests/RequestsComponent.vue').default)
Vue.component('request-component', require('./components/request/RequestComponent.vue').default)
Vue.component('profile-component', require('./components/profile/ProfileComponent.vue').default)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(ToastPlugin)
Vue.use(VueSweetalert2)
Vue.use(ToggleButton)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app-content',
})
