<template>
    <div class="corperate_template_section container">
        <div v-if="displayHeader" class="row toolbar">
            <div class="">
                <div class="page_header">
                    <h3>Corporate Templates</h3>
                    <span
                        ><span>{{ template_count }}</span> Templates</span
                    >
                </div>
            </div>
        </div>
        <div class="corporate_template_list row">
            <div
                v-for="(template, index) in templates"
                class="item"
                :key="index"
            >
                <img :src="template.thumbnail_url" />
                <div class="template_detail">
                    <div class="template_header">{{ template.name }}</div>
                    <div class="template_spec">{{ template.description }}</div>
                    <div class="template_button">
                        <b-link
                            :href="'/template/initiate-order/' + template.id"
                            class=""
                            >Request
                        </b-link>
                        <b-link
                            v-if="isAdmin"
                            @click="onShareClick(template.id)"
                            class="shareLink"
                            variant="link"
                            v-b-modal.modal-1
                        >
                            Share Link
                        </b-link>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modal-1" title="" centered @hide="restValues">
            <div class="loading d-flex align-items-center justify-content-center" v-if="linkLoading">
                <b-spinner label="Loading..."></b-spinner>
            </div>
            <div class="row" v-else>
                <div class="col-md-12 link-text">
                    <div class="header">Copy link to clipboard</div>
                    <div class="detail">
                        This is a public link. Everybody with access to this
                        link will be able to place print requests for that
                        template.
                    </div>
                </div>
                <div class="col-md-12 link-input-container">
                    <div class="copy-link-container">
                        <i class="mdi mdi-link"></i>
                        <b-input
                            :value="template_link"
                            readonly
                            class="link-input"
                        ></b-input>
                        <button
                            class="btn-copy-link"
                            :class="{ copied: linkCopied, 'animate__animated animate__pulse': linkCopyAnimated }"
                            @animationend="linkCopyAnimated = false"
                            @click="copyLink"
                        >
                            {{ linkCopied ? "Copied!" : "Copy" }}
                        </button>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            templates: null,
            template_count: 0,
            template_link: "",
            linkLoading: false,
            isAdmin: false,
            linkCopied: false,
            linkCopyAnimated: false,
        };
    },
    props: ["ggpdata", "displayHeader", "limit"],
    mounted() {
        const ggpData = JSON.parse(this.ggpdata);
        let url = `/template/byGroup/${ggpData.user_group_id}`;
        if (this.limit) {
            url += `?limit=${this.limit}`;
        }
        this.isAdmin = ggpData.isAdmin;
        axios.get(`${url}`).then(response => {
            console.log(response.data);
            this.templates = response.data;
            this.template_count = response.data.length;
        });
    },
    methods: {
        onShareClick(templateId) {
            this.linkLoading = true;
            axios
                .post(`/template/generate-link`, { templateId })
                .then(response => {
                    this.template_link = response.data.data.link;
                    this.linkLoading = false;
                });
        },
        copyLink(event) {
            this.linkCopyAnimated = true;
            this.linkCopied = true;

            const clipboardData =
                event.clipboardData ||
                window.clipboardData ||
                event.originalEvent?.clipboardData ||
                navigator.clipboard;

            clipboardData.writeText(this.template_link);
        },
        restValues(){
            this.linkCopied = false
        }
    }
};
</script>
