<template>
  <div>
    <div class="main-header">
      <div class="header-text">Welcome back, {{ username }}</div>
      <p>
        <small
          >You have <a href="#">{{ totalOpenRequestCount }}</a> open request
          {{ totalOpenRequestCount.length > 0 ? "s" : "" }}</small
        >
      </p>
    </div>
    <hr />
    <div class="corperate_template_section">
      <div class="header">
        <div class="header-content">
          <h3 class="">Corporate Templates</h3>
          <p>
            <small
              >Build your own corporate business cards within minutes</small
            >
          </p>
        </div>

        <a href="/template" class="view-all"
          >View All
          <span class="mdi mdi-arrow-top-right"></span>
        </a>
      </div>
      <template-component :limit="4" :ggpdata="ggpdata"></template-component>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      templates: null,
      totalOpenRequestCount: 0,
    };
  },
  props: ["username", "user_group_id", "ggpdata"],
  mounted() {
    let data = JSON.parse(this.ggpdata);
    if (data.totalOpenRequestCount) {
      this.totalOpenRequestCount = data.totalOpenRequestCount;
    }
  },
};
</script>
